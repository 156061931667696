@mixin font-merchant {
  font-family: $body-fonts;
  font-weight: 500;
  font-size: px(13.5);
  line-height: 105%;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @include bp(desktop) {
    font-size: px(10);
  }
}

@mixin font-monument {
  font-family: $display-fonts;
  font-weight: 500;
  font-size: px(18);
  line-height: 105%;
  letter-spacing: -0.02em;

  @include bp(desktop) {
    font-size: px(14);
  }
}

@mixin font-alt {
  font-family: $alt-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(30);
  line-height: 150%;
  letter-spacing: 0;

  @include bp(mobile) {
    font-size: px(20);
  }
}
