@import "shared";
$pill-button-padding: px(2);

.PillButton {
  @include font-merchant;
  appearance: none;
  border: 0;
  border-radius: px(6);
  overflow: hidden;
  position: relative;
  font-size: px(13.5);
  display: block;
  padding: 0;

  @include bp(desktop) {
    font-size: px(13.5);
  }

  @include bp(mobile) {
    font-size: px(11);
    line-height: px(11);
  }

  &.infoButton {
    @include font-monument;
    height: $portfolio-ui-height;
    padding-left: px(10);
    padding-right: px(10);
    box-sizing: border-box;
    width: fit-content;
    box-shadow: inset 0 px(2) 0 rgba($white, 0.2);
  }

  &.red {
    background-color: $red;
  }

  &.purple {
    background-color: $purple;
  }

  &.white {
    background-color: $white;
  }
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  height: 100%;
  padding: $pill-button-padding;
}

.textHover {
  color: $black;
  position: absolute;
  left: 0;
  top: calc(100% + #{$pill-button-padding * 0.5});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoIcon {
  max-width: px(17);
  min-width: px(17);
  margin-left: px(10);
}
