@import "shared";
$width: 38%;

.PortfolioItemThumbnail {
  // stylelint-disable-next-line
  --extra-margin: 0px;
  --width: 45%;

  @include bp(tablet) {
    --width: 58%;
  }

  @include bp(mobile) {
    --width: 77%;
  }

  &.isHovered {
    z-index: var(--number-of-items) !important;
  }

  &.full {
    width: var(--width);
  }

  &.portrait {
    --extra-margin: #{px(212)};

    @include bp(mobile) {
      // stylelint-disable-next-line
      --extra-margin: 0px;
    }
  }

  &.square {
    --extra-margin: calc(calc(var(--width) - var(--content-width)) * 0.5);

    @include bp(mobile) {
      // stylelint-disable-next-line
      --extra-margin: 0px;
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  height: 26vw;

  @include bp(tablet) {
    height: 33vw;
  }

  @include bp(mobile) {
    height: 45vw;
  }

  .full & {
    width: 100%;
    aspect-ratio: 100 / 57;
  }

  .portrait & {
    aspect-ratio: 84 / 100;

    @include bp(mobile) {
      height: 77vw;
    }
  }

  .square & {
    aspect-ratio: 1 / 1;

    @include bp(mobile) {
      height: 77vw;
    }
  }

  .portrait &,
  .square & {
    width: fit-content;
  }
}

.content {
  height: 100%;
  width: 100%;
  position: relative;

  .portrait & {
    width: 18vw;

    @include bp(tablet) {
      width: 27vw;
    }

    @include bp(mobile) {
      width: 55vw;
    }
  }
}

.explicitBadge {
  position: absolute;
  bottom: px(15);
  right: 0;
  pointer-events: none;
  width: 7vw;
  height: unset;
  z-index: 5;
  margin-right: px(-12);
  opacity: 1;

  @include bp(mobile) {
    width: 20vw;
  }
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  z-index: 2;
}

.imageContainer__inner {
  height: 120%;
  width: 100%;
}

.imageContainer__scaleContainer {
  width: 100%;
  height: 100%;
}

.image {
  @include position-100(absolute);
  pointer-events: none;

  .full &,
  .portrait & {
    object-fit: cover;
  }

  .square & {
    position: relative;
    left: auto;
    top: auto;
    height: 100%;
    width: auto;
  }
}

.video {
  @include position-100(absolute);
  object-fit: cover;
  pointer-events: none;
  filter: blur(0); // fixes jaggy rotation edges
  opacity: 0;
}

.metaDataTitleContainer {
  color: $white;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(#{px(65)}, #{px(65)});
  gap: px(25);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;

  [data-type='gallery'] & {
    grid-template-columns: minmax(0, 1fr) minmax(#{px(25)}, #{px(25)});
  }

  @include bp(tablet) {
    bottom: auto;
    top: 100%;
  }

  @include bp(mobile) {
    padding: px(1) 0;
    gap: px(15);
  }

  @include ticker-styling {
    height: px(18) !important;
    width: 100% !important;

    @include bp(desktop) {
      height: px(15) !important;
    }
  }
}

.metaDataBg {
  @include position-100(absolute);
  background-color: $black;
  z-index: 1;
}

.title {
  @include font-merchant;
  display: inline-block;
  padding: 0;
  white-space: nowrap;
  padding-right: px(15);

  @include bp(desktop) {
    line-height: 0.8;
  }

  @include bp(mobile) {
    font-size: px(10.5);
  }
}

.titleTicker {
  @include bp(desktop) {
    line-height: 0.8;
  }
}

.titleContainer {
  display: inline-block;
  position: relative;
  z-index: 2;
  white-space: nowrap;

  @include bp(desktop) {
    line-height: 0.8;
  }
}

.metaData {
  @include font-monument;
  font-style: italic;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: px(2);
  position: relative;
  z-index: 2;
  height: 100%;

  @include bp(mobile) {
    font-size: px(15);
  }
}
