@use 'sass:map';
@import 'layout';

/* ========================================================================
Breakpoints
======================================================================== */
$layout-mobile: map.get($layout, mobile) + px;
$layout-tablet: map.get($layout, tablet) + px;
$layout-laptop: map.get($layout, laptop) + px;
$layout-desktop: map.get($layout, desktop) + px;
$layout-xl: map.get($layout, xl) + px;

/* ========================================================================
Colors
======================================================================== */
$white: #fff;
$off-white: #fafafa;
$black: #000;
$off-black: #0a0a0a;
$off-black-2: #1b1b1b;
$grey: #f5f5f5;
$grey-2: #333;
$red: #eb4f27;
$purple: #d763f7;
$green: #27c718;
$green-light: #0dff00;
$pink: #e9b8d5;
$teal: #b5e0d7;

/* ========================================================================
General
======================================================================== */
$page-padding: px(15);

/* ========================================================================
Homepage
======================================================================== */
$pattern-interval: 9;

/* ========================================================================
Portfolio item page
======================================================================== */
$portfolio-ui-height: px(31);
$portfolio-ui-padding: px(23);
$portfolio-ui-padding-mobile: px(13);
$portfolio-gallery-list-width: px(115);
$portfolio-gallery-list-margin: px(5);

/* ========================================================================
Fonts
======================================================================== */
$display-font-name: 'monument';
$body-font-name: 'merchant';
$alt-font-name: 'htc-hand';
$display-fonts: $display-font-name, helvetica, arial, sans-serif;
$body-fonts: $body-font-name, helvetica, arial, sans-serif;
$alt-fonts: $alt-font-name, helvetica, arial, sans-serif;

/* ========================================================================
z-index data (see z-index mixin)
======================================================================== */
$elements: loader, main, portfolioItemScroller, homeLogo, aboutModalBg, homeUI, wipe, loadableImagesContainer,
  galleryPannableContainer, actionsUi, detailsUI, cursor;
