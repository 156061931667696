@import "shared";
.RichText {
  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  p {
    margin: px(20) 0;
  }
}

.highlighted {
  background-color: $green;
  line-height: 1.3;
  display: inline-block;
}

.displayText {
  @include font-alt;
  text-transform: none;
  margin-bottom: px(25);
  line-height: 0%;
  padding-right: px(35);

  .highlighted {
    line-height: 0.8;
  }
}
