@import "shared";
.LogoContainer {
  z-index: calc(var(--number-of-items) - 1);
  position: fixed;
  top: 0;
  width: 100%;
  pointer-events: none;

  @include bp(mobile) {
    z-index: 3;
    height: 100%;
  }
}

.logo {
  width: 100%;
  pointer-events: none;
  transition: opacity var(--hover-touch-transition);

  @include bp(mobile) {
    @include position-100(absolute);
    object-fit: contain;
    object-position: top center;
    top: px(20);
  }

  &.hoverLogo {
    z-index: 2;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    @include bp(mobile) {
      @include position-100(absolute);
      object-fit: contain;
      object-position: top center;
      top: px(20);
    }
  }

  &.desktop {
    display: block;

    @include bp(mobile) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @include bp(mobile) {
      display: block;
    }
  }

  .isHover & {
    &.hoverLogo {
      opacity: 1;
    }
  }
}
