@import "shared";
$top-offset: px(44);
$inner-padding: px(25);

.AboutModal {
  @include z-index(homeUI);
  height: calc(100% - #{$top-offset + px(81)});
  top: $top-offset;
  left: $page-padding;
  transform: translateX(-130%) rotate(-10deg);
  position: fixed;
  width: px(620);
  display: flex;
  flex-direction: column;

  @include bp(mobile) {
    height: calc(100% - #{$top-offset + $page-padding});
    width: calc(100% - #{px(40)});
    max-width: px(450);
  }
}

.inner {
  height: 100%;
  background: $off-white;
  box-shadow: 0 1px 0 rgba($black, 10%);
  border-radius: px(23);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.innerScroll {
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.close {
  @include reset-button;
  @include font-merchant;
  position: absolute;
  top: $inner-padding;
  right: $inner-padding;
  z-index: 5;
}

.rta {
  @include font-merchant;
  line-height: 140%;
  padding: $inner-padding px(90) $inner-padding $inner-padding;

  @include bp(mobile) {
    padding: px(20) px(14);
  }

  p {
    padding-right: px(110);

    @include bp(mobile) {
      padding-right: 0;
    }
  }
}

.peopleContainer {
  padding: #{$inner-padding * 2.5} $inner-padding $inner-padding;
  position: relative;
  margin-top: auto;

  @include bp(mobile) {
    padding: px(20) px(14);
  }
}

.peopleTitle {
  @include font-merchant;
  border-bottom: px(1) solid rgba($black, 0.25);
  padding-bottom: px(14);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.peopleList {
  @include reset-ul;
}

.peopleList__item {
  @include font-merchant;
  border-bottom: px(1) dashed rgba($black, 0.25);
  padding: px(8) 0;
}

.imageContainer {
  width: 100%;
  height: px(380);
  position: absolute;
  top: #{$inner-padding * 1.5};
  right: 0;
  max-height: 100%;
  pointer-events: none;

  @include bp(mobile) {
    height: px(250);
    top: 0;
  }
}

.image {
  @include position-100(absolute);
  left: auto;
  right: $inner-padding * 3;
  object-fit: contain;
  object-position: right center;

  @include bp(mobile) {
    right: $inner-padding;
  }
}

.contactMethods {
  background-color: $grey;
  padding: px(22) $inner-padding;
  display: flex;
  flex-direction: column;
  gap: px(5);
  margin-top: auto;
}

.contactMethods__method {
  display: grid;
  grid-template-columns: minmax(0, 0.35fr) minmax(0, 0.65fr);
  align-items: center;
  justify-content: center;
}

.contactMethods__type {
  @include font-merchant;
  font-size: px(12.5);
  text-align: left;
  white-space: nowrap;
}

.contactMethods__value {
  @include font-monument;
  white-space: nowrap;
  margin-top: px(-2);
}

.aboutModalBg {
  @include z-index(aboutModalBg);
  @include position-100(absolute);
  opacity: 0;
  display: none;
}
