@import "shared";
.HomeContent {
  // Keep for reference
}

.contactButton,
.aboutButton {
  @include z-index(homeUI);
  position: fixed !important;
  opacity: 0;
}

.contactButton {
  top: $page-padding;
  right: $page-padding;
}

.aboutButton {
  top: $page-padding;
  left: $page-padding;
}
