@import "shared";
.Footer {
  @include z-index(homeUI);
  position: fixed;
  bottom: px(20);
  padding: 0 $page-padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: px(40);
  color: var(--fg);
  opacity: 0;

  --fg: #{$pink};

  &.isHovering {
    --fg: #{$teal};
  }

  @include bp(mobile) {
    display: none;
  }
}

.brand {
  @include font-monument;
  transition: color var(--hover-touch-transition);
  text-transform: uppercase;
  font-style: italic;
  white-space: nowrap;
}

.longText {
  @include font-merchant;
  transition: color var(--hover-touch-transition);
  line-height: 1.3;
  transform: translateY(#{px(-2)});
  padding: 0 px(20);
  display: inline-block;
  white-space: nowrap;
}

.copyright {
  @include font-monument;
  transition: color var(--hover-touch-transition);
  white-space: nowrap;
  text-transform: uppercase;
}
