@import "shared";
.PortfolioItemScroller {
  @include z-index(portfolioItemScroller);
  position: relative;

  &.animateInComplete {
    overflow: auto;
  }
}

.scrollList {
  @include reset-ul;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 40vh;
  padding-bottom: px(168);

  @include bp(mobile) {
    padding-top: 35vh;
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-bottom: 10vh;
  }
}

.scrollList__item {
  position: relative;

  @include hover {
    z-index: var(--number-of-items) !important;
  }

  &:nth-of-type(#{$pattern-interval}n + 1) {
    margin-left: calc(var(--extra-margin) + 8vw);
    margin-top: 5vw;
    z-index: 2;

    @include bp(tablet) {
      z-index: 3;
      margin-top: 0;
    }

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: 3vw;
      z-index: 1;
    }
  }

  &:nth-of-type(#{$pattern-interval}n + 2) {
    margin-left: calc(var(--extra-margin) + 50vw);
    margin-top: -17vw;
    z-index: 1;

    @include bp(tablet) {
      margin-top: -13vw;
      margin-left: calc(var(--extra-margin) + 40vw);
      z-index: 2;
    }

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: auto;
      margin-right: 6vw;
      z-index: 4;
    }
  }

  &:nth-child(#{$pattern-interval}n + 3) {
    margin-left: calc(var(--extra-margin) + 2vw);
    margin-top: -10vw;
    z-index: 1;

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: 3vw;
      z-index: 1;
    }
  }

  &:nth-child(#{$pattern-interval}n + 4) {
    margin-left: calc(var(--extra-margin) + 25vw);
    margin-top: 1.5vw;
    z-index: 1;

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: auto;
      margin-right: 1vw;
      z-index: 4;
    }
  }

  &:nth-child(#{$pattern-interval}n + 5) {
    margin-left: calc(var(--extra-margin) + 52vw);
    margin-top: 7vw;
    z-index: 2;

    @include bp(tablet) {
      margin-left: calc(var(--extra-margin) + 38vw);
    }

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: 5vw;
      z-index: 4;
    }
  }

  &:nth-child(#{$pattern-interval}n + 6) {
    margin-left: calc(var(--extra-margin) + 1vw);
    margin-top: -18vw;
    z-index: 1;

    @include bp(tablet) {
      margin-top: 4vw;
    }

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: auto;
      margin-right: 8vw;
      z-index: 1;
    }
  }

  &:nth-child(#{$pattern-interval}n + 7) {
    margin-left: calc(var(--extra-margin) + 53vw);
    margin-top: -5vw;
    z-index: 1;

    @include bp(tablet) {
      margin-left: calc(var(--extra-margin) + 38vw);
    }

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: 3vw;
      z-index: 4;
    }
  }

  &:nth-child(#{$pattern-interval}n + 8) {
    margin-left: calc(var(--extra-margin) + 15vw);
    margin-top: -7vw;
    z-index: 1;

    @include bp(tablet) {
      margin-top: 7vw;
    }

    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: auto;
      margin-right: 3vw;
      z-index: 4;
    }
  }

  &:nth-child(#{$pattern-interval}n + 9) {
    margin-left: calc(var(--extra-margin) + 45vw);
    margin-top: -9vw;
    z-index: 1;

    @include bp(tablet) {
      margin-left: calc(var(--extra-margin) + 36vw);
    }
    @include bp(mobile) {
      margin-top: 4vw;
      margin-left: 9vw;
      z-index: 1;
    }
  }
}
