@import "shared";
@import 'shared';

.Ticker {
  width: 100%;

  &.disableHover {
    pointer-events: none;
  }
}
